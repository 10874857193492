import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Thirdsection = () => {
    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        const fetchRooms = async () => {
            try {
                const response = await fetch('https://api.abhyaranyan.com/api/Abhyaranyan/Homepage');
                const data = await response.json();
                if (data.succeeded && data.data && data.data.roomsList) {
                    setRooms(data.data.roomsList);
                }
            } catch (error) {
                console.error('Error fetching room data:', error);
            }
        };

        fetchRooms();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            <section className="ticket-section section-b-space white-section animated-section">
                <div className="animation-section">
                    <div className="cross po-1"></div>
                    <div className="cross po-2"></div>
                    <div className="cross po-3"></div>
                    <div className="round po-4"></div>
                    <div className="round po-5"></div>
                    <div className="round r-2 po-6"></div>
                    <div className="round r-2 po-7"></div>
                    <div className="round r-y po-8"></div>
                    <div className="round r-y po-9"></div>
                    <div className="square po-10"></div>
                    <div className="square po-11"></div>
                    <div className="square s-2 po-12"></div>
                </div>
                <div className="container">
                    <div className="title-3">
                        <span className="title-label">about</span>
                        <h2>rooms & suits<span>hotel</span></h2>
                    </div>


                    <Slider {...settings} className="slide-1">
                        {rooms.map(room => (
                            <div key={room.id}>
                                <div className="row">
                                    <div className="col-lg-7 offset-lg-4 col-md-10 offset-md-1">
                                        <div className="ticket-box">
                                            <div className="ticket-title">
                                                <h6>see rooms</h6>
                                                <span><i className="fas fa-key"></i></span>
                                            </div>
                                            <div className="image-box">
                                                <img
                                                    src={`https://admin.abhyaranyan.com/RoomsUploadimage/${room.roomImage}`}
                                                    className="img-fluid lazyload"
                                                    alt={room.roomType}
                                                />
                                            </div>
                                            <div className="content">
                                                <div className="detail">
                                                    <h4><span>₹{room.rent}</span> / per night</h4>
                                                    <h2>{room.roomType} {room.roomNumber}</h2>
                                                    <h6>{room.roomType} <span>room</span></h6>
                                                    <p>{room.description}</p>
                                                    <a href="#" className="btn btn-solid">book now</a>
                                                </div>
                                                <div className="barcode-design">
                                                    <img
                                                        src="../assets/images/barcode.png"
                                                        alt="barcode"
                                                        className="img-fluid lazyload"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                </div>
            </section>
        </div>
    );
}

export default Thirdsection;


