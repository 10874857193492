import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const Specialsection = () => {
    const [specialRooms, setSpecialRooms] = useState([]);

    useEffect(() => {
        const fetchSpecialRooms = async () => {
            try {
                const response = await fetch('https://api.abhyaranyan.com/api/Abhyaranyan/Homepage');
                const data = await response.json();
                if (data.succeeded && data.data && data.data.specialRooms) {
                    setSpecialRooms(data.data.specialRooms);


                }
            } catch (error) {
                console.error('Error fetching special rooms data:', error);
            }
        };

        fetchSpecialRooms();
    }, []);



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div>
            <section className="section-b-space special-section ratio2_3 animated-section">
                <div className="animation-section">
                    <div className="cross po-1"></div>
                    <div className="cross po-2"></div>
                    <div className="cross po-3"></div>
                    <div className="round po-4"></div>
                    <div className="round po-5"></div>
                    <div className="round r-2 po-6"></div>
                    <div className="round r-2 po-7"></div>
                    <div className="round r-y po-8"></div>
                    <div className="round r-y po-9"></div>
                    <div className="square po-10"></div>
                    <div className="square po-11"></div>
                    <div className="square s-2 po-12"></div>
                </div>
                <div className="container">
                    <div className="title-3">
                        <span className="title-label">our</span>
                        <h2>special room<span>special</span></h2>
                    </div>
                    <Slider {...settings} className="slide-3">
                        {specialRooms.map(room => (
                            <div key={room.id}>
                                <div className="special-box p-0">
                                    <div className="special-img">
                                        <a href="#">
                                            <img
                                                src={`https://admin.abhyaranyan.com/RoomsUploadimage/${room.roomImage}`}

                                                className="img-fluid lazyload bg-img"
                                                alt={room.roomType}
                                                style={{ width: "100%" }}
                                            />
                                        </a>
                                        <div className="label">
                                            <i className="fas fa-percent"></i>
                                            <h3><del>₹{room.oldRent}</del> ₹{room.rent}</h3>
                                        </div>
                                    </div>
                                    <div className="special-content">
                                   
                                            <h5>{room.roomType} - {room.roomNumber}</h5>
                                     
                                        <p>{room.description}</p>
                                        {/* <div className="bottom-part">
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="far fa-star"></i>
                                            </div>
                                            <div className="package-cls">5 day | 4 night</div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    );
}

export default Specialsection;
