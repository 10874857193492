import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Blogs.css';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [banners, setBanners] = useState([]);


    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BannerList');
                console.log('API Response:', response.data); // Check the response structure
                if (Array.isArray(response.data.data.blog)) {
                    setBanners(response.data.data.blog);
                    console.log("-----", response.data.data.blog);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };

        fetchBanners();

    }, []);


    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get('https://api.abhyaranyan.com/api/Abhyaranyan/BlogList');
                setBlogs(response.data.data); // Assuming 'data' contains the list of blogs
                setLoading(false);
            } catch (err) {
                setError('Failed to load blogs.');
                setLoading(false);
            }
        };

        fetchBlogs();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div>
            <Header />
            <section className="breadcrumb-section pt-0">
                {Array.isArray(banners) && banners.map((banner, index) => (
                    <div className="home_section hotel_layout slide-1 p-0">
                        <div>
                            <div className="home">
                                <img src={`https://admin.abhyaranyan.com/RoomsUploadimage/${banner.bannerImage}`} class="bg-img img-fluid blur-up lazyload" alt="" />
                                <div className="home-content">
                                    <div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}

            </section>
            <section className="section-b-space bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog_section blog-inner ratio_landscape">
                                <div className="row blog-list">
                                    {blogs.map((blog) => (
                                        <div className="col-12" key={blog.id}>
                                            <div className="blog-wrap">
                                                <div className="blog-image">
                                                    <a href="#">
                                                        <Link to={`/blogs/${blog.id}`}>  <img
                                                            src={`https://admin.abhyaranyan.com/RoomsUploadimage/${blog.image}`}
                                                            className="img-fluid blur-up lazyload bg-img"
                                                            alt={blog.title}
                                                            style={{ width: '100%' }}
                                                        /></Link>
                                                    </a>
                                                </div>
                                                <div className="blog-details">
                                                    <div>

                                                        <a>
                                                            <h5 ><Link to={`/blogs/${blog.id}`} style={{ color: "#ef3f3e" }}>{blog.title}</Link></h5>
                                                        </a>
                                                        <p>{blog.description}</p>
                                                        <h6 className="link">
                                                            <Link to={`/blogs/${blog.id}`}><a>read more</a></Link>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default Blogs;
